import {
  ref,
  getCurrentInstance,
  reactive,
  defineComponent
} from 'vue'
import TypeAgentForm from '@/views/master/trips/type-agent/TypeAgentForm.vue'
import TableDefault from '@/views/shared-components/table/Default.vue'
import {
  agentTypeUseCase
} from '@/domain/usecase'
import {
  useStore
} from 'vuex'
import { useRoute } from 'vue-router'
import OverlayPanel from 'primevue/overlaypanel'
import { TMsAgentTypes } from '@/data/source/remote/api/backend/V2/msAgentTypes-model'

const endpoint = '/backend/v2/msagenttypes'

export default defineComponent({
  name: 'TypeAgent',
  components: {
    OverlayPanel,
    TableDefault,
    TypeAgentForm
  },
  setup() {
    const store = useStore()
    const app = getCurrentInstance()
    const {
      $confirm,
      $toast,
      $strInd
    } = app!.appContext.config.globalProperties
    const dataTipeAgent = ref([])
    const filter = {
      offset: 0,
      top: 10,
      skip: 0
    }
    const totalRecords = ref(0)
    const sliderActive = ref(false)
    const inputSearch = ref(null)
    const sendInputSearch = ref('')
    const dataForm = ref<TMsAgentTypes | null>(null)
    const refOp = ref<InstanceType<typeof OverlayPanel>>()
    const refTableDefault = ref<InstanceType<typeof TableDefault>>()
    const paramsTable = reactive({
      endpoint,
      columns: [{
        field: 'Tipe',
        headerName: 'Kode Tipe Agen',
        style: {
          width: '200px'
        }
      },
      {
        field: 'Nama',
        headerName: 'Nama Tipe'
      },
      {
        field: 'Action',
        headerName: 'Aksi',
      }],
      showColumnActivate: false,
      labelBtnAdd: 'Tambah Tipe Agen',
      title: 'Tipe Agen',
      fieldSearch: ['filter']
    })
    const route = useRoute()

    const redirectAdd = () => {
      dataForm.value = null
      sliderActive.value = true
    }
    const closeSidebar = () => {
      sliderActive.value = false
    }
    const showDropDown = (evt: any, data: any) => {
      dataForm.value = data
      // eslint-disable-next-line no-unused-expressions
      refOp.value?.toggle(evt)
    }
    const editData = (data: any) => {
      dataForm.value = data
      sliderActive.value = true
    }
    const reloadData = () => {
      // eslint-disable-next-line no-unused-expressions
      refTableDefault.value?.reloadTable()
    }
    const deleteData = (data: typeof dataForm.value) => {
      $confirm.require({
        header: 'Tipe Agen',
        message: $strInd.confirm.msgDelete,
        icon: 'pi pi-exclamation-triangle',
        accept: async () => {
          store.dispatch('showLoading')
          const {
            error
          } = await agentTypeUseCase.delete(Number(data?.Id))
          if (error) {
            $toast.add({
              severity: 'error',
              detail: $strInd.toast.errorDelete,
              group: 'bc',
              life: 3000
            })
          } else {
            $toast.add({
              severity: 'success',
              detail: $strInd.toast.successDeleteData,
              group: 'bc',
              life: 3000
            })
            reloadData()
          }
          store.dispatch('hideLoading')
        }
      })
    }
    return {
      dataTipeAgent,
      filter,
      store,
      totalRecords,
      sliderActive,
      inputSearch,
      sendInputSearch,
      dataForm,
      redirectAdd,
      closeSidebar,
      refOp,
      showDropDown,
      editData,
      deleteData,
      app,
      route,
      paramsTable,
      refTableDefault,
      reloadData
    }
  }
})
