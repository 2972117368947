import {
  reactive,
  ref,
  getCurrentInstance,
  onMounted
  // watch
} from 'vue'
import {
  agentTypeUseCase
} from '@/domain/usecase'
import useVuelidate from '@vuelidate/core'
import {
  required,
  helpers
} from '@vuelidate/validators'
import { useStore } from 'vuex'
import { useRoute } from 'vue-router'
import { isEmpty } from 'lodash'
import { TObjSelect } from '@/utils/types/tOptionSelect'
import MultiselectAsync from '@/views/shared-components/select/MultiselectAsync.vue'

const endpointItemCategory = '/backend/v2/mskategoribarangs'

export default {
  name: 'TypeAgentForm',
  components: {
    MultiselectAsync
  },
  props: {
    data: Object
  },
  emits: ['closeSidebar', 'reloadData'],
  setup(props: any, {
    emit
  }: any) {
    const app = getCurrentInstance()
    const route = useRoute()
    const isActive = ref(true)
    const nameAgentType = ref(null)
    const authorityGoodsId = ref([])
    const codeAgentType = ref('')
    const propertiesProps = reactive(props)
    const authorityGoodsOption = ref([])
    const title = ref('Tambah Tipe Agen')
    const {
      $confirm,
      $toast,
      $strInd
    } = app!.appContext.config.globalProperties
    const store = useStore()
    const submitted = ref(false)
    const dataForm = reactive({
      nameAgentType: '',
      codeAgentType: '',
      authorityGoodsId: [] as Array<TObjSelect>,
      isActive: true,
    })
    const rules = {
      codeAgentType: {
        required: helpers.withMessage('Kode Tipe Agen harus diisi', required)
      },
      nameAgentType: {
        required: helpers.withMessage('Nama Tipe Agen harus diisi', required)
      },
      authorityGoodsId: {
        required: helpers.withMessage('Wewenang Barang harus diisi', required)
      }
    }
    const v$ = useVuelidate(rules, dataForm)

    const closeSidebar = () => {
      emit('closeSidebar')
    }
    const checkResponse = async (response: any) => {
      if (response.error) {
        $toast.add({
          severity: 'error',
          detail: response.message,
          group: 'bc',
          life: 1500
        })
      } else {
        if (propertiesProps.data) {
          $toast.add({
            severity: 'success',
            // summary: response.result.title,
            detail: response.result.detail ?? response.result.Detail,
            group: 'bc',
            life: 1500
          })
        } else {
          $toast.add({
            severity: 'success',
            detail: $strInd.toast.successSubmit,
            group: 'bc',
            life: 1500
          })
        }
        emit('reloadData')
        closeSidebar()
      }
      store.dispatch('hideLoading')
    }

    const submitProccess = async () => {
      store.dispatch('showLoading')
      const payload = {
        Tipe: dataForm.codeAgentType.toUpperCase(),
        Nama: dataForm.nameAgentType,
        IsActive: dataForm.isActive,
        ItemCategories: dataForm.authorityGoodsId.map((x) => ({
          KategoriBarangId: Number(x.value),
        }))
      }
      const id = Number(propertiesProps?.data?.Id) ?? null
      const response = await agentTypeUseCase.saveData(id, payload)

      checkResponse(response)
    }

    const submitData = (isFormValid: boolean) => {
      submitted.value = true
      if (!isFormValid) {
        return
      }
      submitProccess()
    }

    const deleteData = () => {
      $confirm.require({
        header: 'Tipe Agen',
        message: $strInd.confirm.msgDelete,
        icon: 'pi pi-exclamation-triangle',
        accept: async () => {
          store.dispatch('showLoading')
          const {
            error
          } = await agentTypeUseCase.delete(propertiesProps.data.Id)
          if (error) {
            $toast.add({
              severity: 'error',
              detail: $strInd.toast.errorDelete,
              group: 'bc',
              life: 3000
            })
            store.dispatch('hideLoading')
          } else {
            $toast.add({
              severity: 'success',
              detail: $strInd.toast.successDeleteData,
              group: 'bc',
              life: 3000
            })
            emit('reloadData')
            closeSidebar()
            store.dispatch('hideLoading')
          }
        }
      })
    }

    const getDetailForm = async () => {
      try {
        store.dispatch('showLoading')
        const {
          result,
          error
        } = await agentTypeUseCase.getDetail(Number(propertiesProps.data.Id))
        if (!error) {
          dataForm.isActive = result.IsActive
          dataForm.codeAgentType = result.Tipe
          dataForm.nameAgentType = result.Nama
          dataForm.authorityGoodsId = result.ItemCategories.map((v) => ({
            label: v.KategoriBarangNama ?? '',
            value: v.KategoriBarangId
          }))
        }
        store.dispatch('hideLoading')
      } catch (error) {
        store.dispatch('hideLoading')
      }
    }
    onMounted(() => {
      if (!isEmpty(propertiesProps.data)) {
        title.value = 'Edit Tipe Agen'
        getDetailForm()
      }
    })
    return {
      isActive,
      nameAgentType,
      codeAgentType,
      closeSidebar,
      title,
      submitProccess,
      checkResponse,
      authorityGoodsId,
      deleteData,
      // getAgentDetail,
      authorityGoodsOption,
      dataForm,
      rules,
      v$,
      submitted,
      route,
      submitData,
      endpointItemCategory
    }
  }
}
