
/* eslint-disable no-useless-catch, vue/no-dupe-keys  */
import {
  reactive,
  ref,
  defineComponent,
  onMounted,
  toRefs,
  getCurrentInstance
} from 'vue'
import type {
  PropType
} from 'vue'
import {
  get
} from 'lodash'
import {
  useRoute
} from 'vue-router'
import Paginator, { PageState } from 'primevue/paginator'
import Column from 'primevue/column'
import { Remote, remoteEnum } from '@/data/source/remote/remoteApi'
import returnUrlPrams from '@/utils/helpers/returnUrlPrams'
import RepositoryV2 from '@/data/repositoryV2'

type TObjectOfColumn = {
  field: string
  headerName: string
  visible?: boolean
  isRender?: boolean
  sortable?: boolean
  class?: string
  style?: any,
  fieldSearch?: string
}

export default defineComponent({
  name: 'TableDefault',
  components: {
    // ProgressSpinner,
    Paginator,
    Column
  },
  props: {
    endpoint: {
      type: String,
      default: ''
    },
    rows: {
      type: Number,
      default: 10
    },
    columns: {
      type: Array as PropType<Array<TObjectOfColumn>>,
      default: []
    },
    title: {
      type: String,
      default: ''
    },
    labelBtnAdd: {
      type: String,
      default: ''
    },
    placeholderInputSearch: {
      type: String,
      default: 'Cari Disini...'
    },
    fieldSearch: {
      type: Array as PropType<Array<string>>,
      default: []
    },
    customFilter: {
      default: []
    }
  },
  emits: ['add'],
  setup(props, { emit }) {
    const {
      endpoint,
      rows,
      title,
      columns,
      labelBtnAdd,
      placeholderInputSearch,
      fieldSearch,
      customFilter
    } = toRefs(props)
    const app = getCurrentInstance()
    const {
      $enumBase: enumBase,
      $icon: icon
    } = app!.appContext.config.globalProperties
    const route = useRoute()
    const paginate = reactive({
      pageSize: rows.value,
      pageNumber: 1,
      totalRecords: 0,
    })
    const dataSource = ref([])
    const isLoading = ref(false)
    const inputSearch = ref('')

    const nameSlotColumn = (field: string) => `col-${field}`

    const onSort = (val: any) => {
      console.log('onSort', val)
    }

    const getData = async () => {
      isLoading.value = true
      const urlParams = returnUrlPrams({
        pageNumber: paginate.pageNumber,
        pageSize: paginate.pageSize,
        search: inputSearch.value,
        filterField: fieldSearch.value,
        custom: customFilter.value
      })
      const {
        result,
        error,
        count
      } = await new RepositoryV2(new Remote(remoteEnum.get, `${endpoint.value}${urlParams}`), null).getResult(false)
      if (!error) {
        dataSource.value = result
        paginate.totalRecords = count
      }
      isLoading.value = false
    }

    const onChangePage = (val: PageState) => {
      paginate.pageNumber = val.page + 1
      getData()
    }

    const redirectAdd = () => {
      emit('add')
    }

    const searchData = () => {
      getData()
    }

    const reloadTable = () => {
      getData()
    }

    onMounted(() => {
      getData()
    })

    return {
      route,
      paginate,
      onSort,
      dataSource,
      isLoading,
      get,
      nameSlotColumn,
      columns,
      onChangePage,
      title,
      redirectAdd,
      labelBtnAdd,
      enumBase,
      icon,
      inputSearch,
      searchData,
      reloadTable,
      placeholderInputSearch
    }
  },
})
