import { toDisplayString as _toDisplayString, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, mergeProps as _mergeProps, withCtx as _withCtx } from "vue"

const _hoisted_1 = { id: "type-agent" }
const _hoisted_2 = { class: "color-primary font-semibold" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TableDefault = _resolveComponent("TableDefault")
  const _component_ListCopyEditDel = _resolveComponent("ListCopyEditDel")
  const _component_OverlayPanel = _resolveComponent("OverlayPanel")
  const _component_TypeAgentForm = _resolveComponent("TypeAgentForm")
  const _component_Sidebar = _resolveComponent("Sidebar")

  return (_openBlock(), _createBlock("section", _hoisted_1, [
    _createVNode(_component_TableDefault, _mergeProps(_ctx.paramsTable, {
      ref: "refTableDefault",
      onAdd: _ctx.redirectAdd
    }), {
      "col-Tipe": _withCtx(({ rowData }) => [
        _createVNode("p", _hoisted_2, _toDisplayString(rowData.Tipe), 1)
      ]),
      "col-Action": _withCtx(({ rowData }) => [
        (_ctx.route.meta?.permission?.update || _ctx.route?.meta?.permission?.delete)
          ? (_openBlock(), _createBlock("i", {
              key: 0,
              class: "pi pi-ellipsis-h text-base cursor-pointer text-black",
              "aria:haspopup": "true",
              onClick: ($event: any) => (_ctx.showDropDown($event, rowData))
            }, null, 8, ["onClick"]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }, 16, ["onAdd"]),
    _createVNode(_component_OverlayPanel, { ref: "refOp" }, {
      default: _withCtx(() => [
        _createVNode(_component_ListCopyEditDel, {
          del: _ctx.route?.meta?.permission?.delete,
          edit: _ctx.route?.meta?.permission?.update,
          onDel: _cache[1] || (_cache[1] = ($event: any) => (_ctx.deleteData(_ctx.dataForm))),
          onEdit: _cache[2] || (_cache[2] = ($event: any) => (_ctx.editData(_ctx.dataForm)))
        }, null, 8, ["del", "edit"])
      ]),
      _: 1
    }, 512),
    _createVNode(_component_Sidebar, {
      class: "type-bus-sidebar",
      visible: _ctx.sliderActive,
      "onUpdate:visible": _cache[5] || (_cache[5] = ($event: any) => (_ctx.sliderActive = $event)),
      showCloseIcon: false,
      position: "right"
    }, {
      default: _withCtx(() => [
        (_ctx.sliderActive)
          ? (_openBlock(), _createBlock(_component_TypeAgentForm, {
              key: 0,
              data: _ctx.dataForm,
              onCloseSidebar: _cache[3] || (_cache[3] = ($event: any) => (_ctx.closeSidebar())),
              onReloadData: _cache[4] || (_cache[4] = ($event: any) => (_ctx.reloadData()))
            }, null, 8, ["data"]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }, 8, ["visible"])
  ]))
}